import { Link, graphql } from 'gatsby';
import React, { useContext } from 'react';

import { BgWrapper } from '../components/shared/components';
import BottomCta from '../components/shared/bottom-cta';
import { Breadcrumbs } from '../components/shared/breadcrumbs';
import Layout from '../components/layout';
import { PromoCard } from '../pages/promocii';
import SEO from '../components/seo';
import SectionTitle from '../components/shared/section-titles';
import ShareTab from '../components/shared/share-tab';

const breadcrumbs = [
	{
		text: 'Начало',
		link: '/'
	},
	{
		text: 'Промоции',
		link: '/promocii/'
	}
];

export default ({ data }) => {
	const post = data.post;
	const randomPosts = data.randomPosts;
	let b = breadcrumbs.slice();
	if (b[b.length - 1].text !== post.title) b.push({ text: post.title });

	return (
		<BgWrapper bg={data.bg.childImageSharp.fluid.src}>
			<Layout>
				<SEO
					title={post.title}
					description={post.acf.seo_description}
					image={post.featured_image_url}
				/>
				<div className="container" style={{ paddingTop: '16rem' }}>
					<Link to={'/promocii/'} className="link">
						<span className="icon back-blue-arrow mr-1"></span> Назад
					</Link>
					<h1 className="my-3">{post.title}</h1>
					<Breadcrumbs items={b} />

					<div className="my-4 d-flex justify-content-start">
						<div className="col-12 col-lg-8 p-0 m-0">
							<img
								src={post.featured_image_url}
								className="img-fluid"
								alt={post.title}
							/>
							<div
								className="mt-5"
								dangerouslySetInnerHTML={{ __html: post.content }}
							/>
						</div>
					</div>

					<ShareTab path={post.slug} />

					<BottomCta
						bgImg={data.ctaBg.childImageSharp.fluid.src}
						removeSpacing={true}
					>
						<div
							className="d-flex justify-content-center align-items-center"
							style={{ minHeight: '18rem' }}
						>
							<div>
								<h2 className="mb-4 d-none d-md-block text-white">
									При всякакви въпроси и<br /> запитвания
								</h2>
								<h2 className="mb-5 d-block d-md-none text-white">
									При всякакви въпроси и запитвания
								</h2>
								<a
									href="tel:+359882991611"
									className="btn btn-primary green"
								>
									Обади се на 088 299 1611
								</a>
							</div>
						</div>
					</BottomCta>

					<SectionTitle>
						<h2>Други промоции</h2>
						<hr className="center" />
					</SectionTitle>

					<div className="row mb-5">
						{true &&
							randomPosts.edges.map(({ node }, index) => (
								<div className="col-12 col-lg-4">
									<PromoCard {...node} key={index} isBig={false} />
								</div>
							))}
					</div>
				</div>
			</Layout>
		</BgWrapper>
	);
};

export const query = graphql`
	query($id: String!) {
		post: wordpressWpPromotions(id: { eq: $id }, status: { eq: "publish" }) {
			title
			slug
			date
			content
			featured_image_url
			acf {
				seo_description
				subtitle
			}
		}
		randomPosts: allWordpressWpPromotions(limit: 3, filter: { id: { ne: $id } }) {
			edges {
				node {
					id
					slug
					title
					featured_image_url
					acf {
						subtitle
					}
				}
			}
		}
		ctaBg: file(relativePath: { eq: "promo/promo-cta.png" }) {
			childImageSharp {
				fluid(maxHeight: 700) {
					src
				}
			}
		}
		bg: file(relativePath: { eq: "promo/promo-bg.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					src
				}
			}
		}
	}
`;

